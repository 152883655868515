
@use 'sass:math';

.fire {
  $fireColor: rgb(255,80,0);
  $fireColorT: rgba(255,80,0,0);
  $dur: 1s;
  $blur: 0.02em;
  $fireRad: 3em;
  $parts: 50;
  $partSize: 5em;

  font-size: 24px;
  //filter: blur($blur);
  margin: 3em auto 0 auto;
  width: 100%;
  &:before{
    content: '';
    padding-bottom: 120%;
  }

  .particle {
    animation: rise $dur ease-in infinite;
    background-image: radial-gradient($fireColor 20%,$fireColorT 70%);
    border-radius: 50%;
    mix-blend-mode: screen;
    opacity: 0;
    position: absolute;
    bottom: 0;
    width: $partSize;
    height: $partSize;
    // spread particles out in time and x-position to get desired effect
    @for $p from 1 through $parts {
      &:nth-of-type(#{$p}) {
        animation-delay: $dur * random();
        left: calc((100% - #{$partSize}) * #{math.div($p - 1, $parts)});
      }
    }
  }
  @keyframes rise {
    from {
      opacity: 0;
      transform: translateY(0) scale(1);
    }
    25% {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: translateY(-10em) scale(0);
    }
  }
}
