
.animate {
  &-timeout {
    @apply w-full origin-left;
    height: 4px;
    animation: timeout 5s;
    animation-fill-mode: forwards;
  }
}

.transition {
  &-enter {
    opacity: 0;
    max-height: 0;
    transform: translateX(100%) skew(-19deg);
    transition: .4s cubic-bezier(0.5, -0.25, 0.05, 1.25);
  }

  &-enter-active {
    opacity: 1;
    transform: translateX(0);
    max-height: 1000px;
    transition: .4s cubic-bezier(0.5, -0.25, 0.05, 1.25);
  }

  &-exit {
    opacity: 1;
    max-height: 1000px;
  }

  &-exit-active {
    opacity: 0;
    max-height: 0;
    transform: translateX(-100%) skew(18deg);
    transition: .4s cubic-bezier(0.5, -0.25, 0.015, 1.25);
  }
}

.fade-in {
  &-enter {
    @apply opacity-0;
  }

  &-enter-active {
    @apply opacity-100 transition-all duration-300;
  }

  &-exit {
    @apply opacity-100;
  }

  &-exit-active {
    @apply opacity-0 transition-all duration-300;
  }
}

.scale-in {
  &-enter {
    @apply transform scale-0;
  }

  &-enter-active {
    @apply transform scale-100 transition-all duration-300;
  }

  &-exit {
    @apply transform scale-100;
  }

  &-exit-active {
    @apply transform scale-0 transition-all duration-300;
  }
}

.scale-fade-in {
  &-enter {
    @apply transform scale-0 opacity-0;
  }

  &-enter-active {
    @apply transform scale-100 opacity-100 transition-all duration-300;
  }

  &-exit {
    @apply transform scale-100 opacity-100;
  }

  &-exit-active {
    @apply transform scale-0 opacity-0 transition-all duration-300;
  }
}