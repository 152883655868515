@screen print {
  @page { margin: 0 0 0 0; }
  body { margin: 0 0 0 0; }
  .break-avoid {
    page-break-inside: avoid;
    break-inside: avoid;
  }
  * {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}
