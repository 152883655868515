
@layer base {

  body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply bg-gray-100;
  }

  a, .a {
    @apply text-primary underline transition-colors;
    &:hover {
      @apply text-secondary;
    }
  }

  $inputMinHeight: 32px;
  input:not([type="button"]) {
    min-height: $inputMinHeight;
  }

  select {
    @apply pr-6;
    appearance: none;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23e5e7eb' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: 100% center;
    min-height: $inputMinHeight;
  }

  textarea {
    @apply py-2;
  }

  input:not([type="button"]), select, textarea {
    @apply border-0 border-b border-gray-200 transition-colors;
    background-color: transparent;
    &:focus {
      @apply border-primary outline-none;
    }
  }

  .stretched-link::after {
    content: "";
    @apply z-10 absolute inset-0 bg-transparent pointer-events-auto;
  }

  .input {
    &-lg {
      @apply text-2xl;
    }
  }

  .container {
    @apply max-w-screen-lg mx-auto w-full px-4;
    @screen md {
      @apply px-8;
    }
  }

  #root {
    overflow: hidden;
    position: relative;
  }
}
