@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'base';
@import 'transitions';
@import 'print';

@import 'debug';

@keyframes timeout {
  0% { transform: scaleX(1); }
  100% { transform: scaleX(0); }
}

.animate-timeout {
  @apply w-full origin-left;
  height: 4px;
  animation: timeout 5s;
  animation-fill-mode: forwards;
}
